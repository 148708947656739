import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import Navbar from '../components/Navbar';
import '../styles/global.css'; // Import your global styles
import Footer from '../components/Footer';
import Cookies from 'js-cookie';
import { AdBanner } from '../components/Ads';

const Layout = ({ children }) => {
  const location = useLocation();
  const currentPathname = location.pathname;
  const isToolsPage = currentPathname.includes("/tools");

  const [showCookiesPopup, setShowCookiesPopup] = useState(false);

  useEffect(() => {
    const cookiesAccepted = Cookies.get('cookiesAccepted');
    if (cookiesAccepted) {
      setShowCookiesPopup(false);
    } else {
      setShowCookiesPopup(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    Cookies.set('cookiesAccepted', 'true', { expires: 365 });
    setShowCookiesPopup(false);
  };

  return (
    <div className='transition duration-200 ease-in-out text-gray-700 dark:bg-black dark:text-gray-300'>
      <Navbar sticky={isToolsPage ? false : true} />
      <main className='min-h-screen mx-auto max-w-6xl'>
        {children}
        <AdBanner />
      </main>
      <Footer />
      {showCookiesPopup && (
        <div className="fixed bottom-0 left-0 right-0 bg-gray-900 py-4 px-8 text-white text-center m-4 rounded-xl border border-gray-500">
          We use localStorage to store your theme & Cookies for analystic purporse
          <button
            className="sm:ml-4 bg-white text-gray-800 font-bold py-1 px-3 rounded block mt-2 sm:mt-0 w-full sm:w-auto sm:inline-block"
            onClick={handleAcceptCookies}
          >
            Accept
          </button>
        </div>
      )}
    </div>
  );
};

export default Layout;
