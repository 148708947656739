const statusData = {
  status: true,
  services: [
    { title: 'API Status', status: 'operational' },
    { title: 'SEO Checker', status: 'operational' },
    { title: 'Keywords Checker', status: 'operational' },
    { title: 'WhatPeopleAsks', status: 'operational' },
  ]
};

export default statusData;