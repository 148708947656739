import React from 'react';
import Layout from './src/pages/layout';
import "./src/styles/global.css"

export const wrapPageElement = ({ element, props }) => {
  return (
      <Layout {...props}>
        {element}
      </Layout>
    );
};
