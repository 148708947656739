exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-layout-jsx": () => import("./../../../src/pages/layout.jsx" /* webpackChunkName: "component---src-pages-layout-jsx" */),
  "component---src-pages-status-jsx": () => import("./../../../src/pages/status.jsx" /* webpackChunkName: "component---src-pages-status-jsx" */),
  "component---src-pages-tools-keywords-planner-index-jsx": () => import("./../../../src/pages/tools/keywords-planner/index.jsx" /* webpackChunkName: "component---src-pages-tools-keywords-planner-index-jsx" */),
  "component---src-pages-tools-seo-checker-index-jsx": () => import("./../../../src/pages/tools/seo-checker/index.jsx" /* webpackChunkName: "component---src-pages-tools-seo-checker-index-jsx" */),
  "component---src-pages-tools-what-people-asks-index-jsx": () => import("./../../../src/pages/tools/what-people-asks/index.jsx" /* webpackChunkName: "component---src-pages-tools-what-people-asks-index-jsx" */),
  "component---src-pages-us-contact-index-jsx": () => import("./../../../src/pages/us/contact/index.jsx" /* webpackChunkName: "component---src-pages-us-contact-index-jsx" */),
  "component---src-pages-us-index-jsx": () => import("./../../../src/pages/us/index.jsx" /* webpackChunkName: "component---src-pages-us-index-jsx" */),
  "component---src-pages-us-privacy-index-jsx": () => import("./../../../src/pages/us/privacy/index.jsx" /* webpackChunkName: "component---src-pages-us-privacy-index-jsx" */),
  "component---src-pages-us-terms-index-jsx": () => import("./../../../src/pages/us/terms/index.jsx" /* webpackChunkName: "component---src-pages-us-terms-index-jsx" */)
}

