import React, { useEffect, useState } from 'react';
import { HiMenuAlt4 } from 'react-icons/hi';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import { MdSunny } from "react-icons/md";
import { PiMoonDuotone } from "react-icons/pi";
import { IoClose } from "react-icons/io5";

const Navbar = ({ sticky }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    setTheme(localStorage.theme || 'light')

    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        setShowPopup(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    // Apply the theme class when the theme changes
    applyThemeClass();
    // Update localStorage.theme when the theme changes
  }, [theme]);

  const applyThemeClass = () => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      localStorage.theme = theme;
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.theme = theme;
    }
  };

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  const handleClick = (e) => {
    if (e.target.id === "closeX") {
      togglePopup();
    }
  };

  return (
    <div>
      <nav
        className={`${
          isScrolled && sticky
            ? 'fixed top-0 bg-white backdrop-filter backdrop-blur-md bg-opacity-60 text-black dark:bg-black dark:text-white'
            : `sticky ${!!sticky && !sticky && 'static'} bg-white text-black dark:bg-black dark:text-white`
        } z-50 w-full justify-center border-b border-b-gray-400 dark:border-b-gray-700 transition duration-200 ease-in-out`}
      >
        <div className="flex mx-auto justify-between w-full items-center p-4 px-4 sm:px-8 max-w-6xl">
          <Link to="/" aria-label="Go Home">
            <div className="flex items-center">
              <StaticImage
                className="w-[25px]"
                src='../images/icon.png'
                alt="favicon"
              />
              <span className="text-gray-400 text-xl mx-2 hidden sm:inline-block italic">/</span>
              <b className="hidden text-xl ml-1 sm:inline-block hover:text-gray-600 dark:hover:text-gray-200">VitalsX</b>
            </div>
          </Link>
          <div className='grow hidden md:flex'>
            <ul className="py-2">
              <li className="inline-block ml-4 border-y-2 border-y-transparent hover:underline">
                <Link to="/us/">
                  About
                </Link>
              </li>
              <li className="inline-block ml-4 border-y-2 border-y-transparent hover:underline">
                <div class="relative inline-block text-left dropdown">
                  <span class="rounded-md shadow-sm"
                    ><button class="inline-flex justify-center dark:text-gray-300 w-full leading-5 transition duration-150 ease-in-out" 
                    type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items-117">
                      <span className='hover:underline'>Tools</span>
                      </button>
                    </span>
                  <div class="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95">
                    <div class="absolute right-0 w-56 mt-2 origin-top-right bg-white dark:bg-black border border-gray-200 dark:border-gray-600 divide-y divide-gray-100 rounded-md shadow-lg outline-none" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items-117" role="menu">
                      <div class="py-1">
                        <a href="/tools/seo-checker/" tabindex="0" class="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" >SEO Checker</a>
                        <a href="/tools/keywords-planner/" tabindex="0" class="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" >Keywords Planner</a>
                        <a href="/tools/what-people-asks/" tabindex="0" class="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" >WhatPeopleAsks</a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="inline-block ml-4 border-y-2 border-y-transparent hover:underline">
                <div class="relative inline-block text-left dropdown">
                  <span class="rounded-md shadow-sm"
                    ><button class="inline-flex justify-center dark:text-gray-300 w-full leading-5 transition duration-150 ease-in-out" 
                    type="button" aria-haspopup="true" aria-expanded="true" aria-controls="headlessui-menu-items-117">
                      <span className='hover:underline'>Legal</span>
                      </button>
                    </span>
                  <div class="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95">
                    <div class="absolute right-0 w-56 mt-2 origin-top-right bg-white dark:bg-black border border-gray-200 dark:border-gray-600 divide-y divide-gray-100 rounded-md shadow-lg outline-none" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items-117" role="menu">
                      <div class="py-1">
                        <a href="/us/terms" tabindex="0" class="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" >Terms & Conditions</a>
                        <a href="/us/privacy" tabindex="0" class="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem" >Privacy Policy</a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="flex">
            <button
              className="p-2 ml-1 rounded-full focus:outline-none flex justify-center align-center text-xl"
              onClick={toggleTheme}
              aria-label="Toggle Theme"
              >
              {theme === 'light' ? <PiMoonDuotone /> : <MdSunny className='text-white' />}
            </button>
            <button
              className="bg-transparent p-1 ml-1 rounded-full focus:outline-none md:hidden"
              onClick={togglePopup}
              aria-label="Show More"
              aria-labelledby="more"
            >
              {!showPopup ? <HiMenuAlt4 size={28} /> : <IoClose size={28} />}
            </button>
          </div>
        </div>
      </nav>
      {showPopup && (
        <div
        id='closeX'
          onClick={handleClick}
          className="fixed top-0 left-0 right-0 bottom-0 bg-gray-600 backdrop-filter backdrop-blur-sm bg-opacity-50 md:p-8 md:pt-12 z-[40] md:z-50"
        >
          <div
            className={`p-4 pt-24 md:pt-8 md:p-8 right-0 md:rounded-xl bg-white/90 dark:bg-black/70 md:border-2 dark:border-gray-600 font-semibold text-xl w-full shadow-lg h-full z-50 ease-in-out duration-200`}
          >
            <ul className="py-2">
              <li className="block ml-4 border-y-2 border-y-transparent hover:underline">
                <a href="/us/">
                  About
                </a>
              </li>
              <li className="block ml-4 mt-2 border-y-2 border-y-transparent">
                <span className='hover:underline'>Tools</span>
                <div class="opacity-80">
                  <a href="/tools/seo-checker/" tabindex="0" class="flex justify-between w-full py-2 text-sm leading-5 text-left"  role="menuitem" >SEO Checker</a>
                  <a href="/tools/keywords-planner/" tabindex="0" class="flex justify-between w-full py-2 text-sm leading-5 text-left"  role="menuitem" >Keywords Planner</a>
                  <a href="/tools/what-people-asks/" tabindex="0" class="flex justify-between w-full py-2 text-sm leading-5 text-left"  role="menuitem" >WhatPeopleAsks</a>
                </div>
              </li>
              <li className="block ml-4 border-y-2 border-y-transparent">
                <span className='hover:underline'>Legal</span>
                <div class="opacity-80">
                  <a href="/us/terms" tabindex="0" class="flex justify-between w-full py-2 text-sm leading-5 text-left"  role="menuitem" >Terms & Conditions</a>
                  <a href="/us/privacy" tabindex="0" class="flex justify-between w-full py-2 text-sm leading-5 text-left"  role="menuitem" >Privacy Policy</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;