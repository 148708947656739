import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';

function generateRandomID() {
  // Function to generate a random string of given length
  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  }

  // Function to add leading zeros to a number
  function addLeadingZeros(num, length) {
    return ('0'.repeat(length) + num).slice(-length);
  }

  // Get current date and time components
  const now = new Date();
  const day = addLeadingZeros(now.getDate(), 2);
  const hours = addLeadingZeros(now.getHours(), 2);
  const minutes = addLeadingZeros(now.getMinutes(), 2);

  // Generate a random 10-digit code with alphabets
  const randomCode = generateRandomString(100);

  // Construct the final ID
  const randomID = `${day}:${hours}:${minutes}:${randomCode}`;

  return randomID;
}

function Banner() {
  const id = generateRandomID(100)

  useEffect(() => {
    const appendScriptToElement = () => {
      const data = document.createElement('script');
      data.type = 'text/javascript';
      data.innerHTML = `
        var atOptions = {
          'key' : '6ffa40c4723eb76dee9edc0e78f7065a',
          'format' : 'iframe',
          'height' : 250,
          'width' : 300,
          'params' : {}
        };
      `;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//www.topcreativeformat.com/6ffa40c4723eb76dee9edc0e78f7065a/invoke.js';

      const targetElement = document.getElementById(id);

      if (targetElement) {
        targetElement.innerHTML = '';
        targetElement.appendChild(data);
        targetElement.appendChild(script);
      }
    };

    appendScriptToElement();
  }, [id]); // Include 'id' in the dependency array to update the effect when 'id' changes

  return (
    <div className="bg-gray-100 flex items-center justify-center p-4 rounded-md relative">
      <div className='bg-slate-800 text-white font-bold rounded-md p-1 px-2 top-2 left-2 text-sm absolute z-[5]'>ADS</div>
      <div className='overflow-hidden rounded-md' id={id}></div>
    </div>
  );
}

function AdBanner() {
  const id = generateRandomID(100)
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const appendScriptToElement = () => {
      const data = document.createElement('script');
      data.type = 'text/javascript';
      data.innerHTML = `
        var atOptions = {
          'key' : '3221ef1094cf8f63d184e4696bbea490',
          'format' : 'iframe',
          'height' : 50,
          'width' : 320,
          'params' : {}
        };
      `;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//www.topcreativeformat.com/3221ef1094cf8f63d184e4696bbea490/invoke.js';

      const targetElement = document.getElementById(id);

      if (targetElement) {
        targetElement.innerHTML = '';
        targetElement.appendChild(data);
        targetElement.appendChild(script);
      }
    };

    if (isVisible) {
      appendScriptToElement();
    }
  }, [id, isVisible]);

  const handleClose = () => {
    setIsVisible(false);
  };

  return isVisible ? (
    <div className='flex justify-center items-center p-2 fixed bottom-0 left-0 right-0 w-full z-50'>
      <div className="bg-gray-100 max-w-[320px] overflow-hidden rounded-md relative">
        <div className="flex items-center justify-between">
          <div className='top-1 left-1 absolute bg-slate-500 text-white font-bold rounded-md p-1 backdrop-filter backdrop-blur-sm bg-opacity-30 px-2 text-xs'>ADS</div>
          <div className='overflow-hidden rounded-md' id={id}></div>
          <button className="top-1 right-1 bg-slate-500 text-white rounded-md absolute hover:bg-white p-1 backdrop-filter backdrop-blur-sm bg-opacity-30 hover:text-gray-700" onClick={handleClose}>
            <FaTimes size={15} />
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export { AdBanner }

export default Banner;