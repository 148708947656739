import React from 'react';
import { Link } from 'gatsby';
import { FaInstagram, FaPinterest, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import statusData from '../../data/statusData';

const Footer = () => {
  return (
    <footer id="footer" className="bg-gray-800 text-white p-6 md:p-12 md:px-24 text-base">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="lg:flex flex-wrap justify-between">
          <div className="flex-grow-1 px-4 mt-4 lg:max-w-xs">
            <b>About</b>
            <p className="mt-4 text-gray-400">
              VitalsX SEO-based company committed to helping businesses succeed in the digital world.
            </p>
            <div className='pt-4'>
            <a href="https://www.producthunt.com/posts/vitals-x?utm_source=badge-featured&utm_medium=badge&utm_source=badge-vitals-x" target="_blank">
              <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=407194&theme=light" alt="Vitals X - Unleash Your Website's Potential with Free SEO Checker Tools | Product Hunt" style={{ width: "250px", height: "54px" }} width="250" height="54" />
            </a>
            </div>
            <Link to={'/status'} className="mt-4 bg-gray-700 p-4 rounded-xl inline-block border border-gray-500">
              {statusData.status ? (
                <span className="flex items-center">
                  <b className='mr-2'>Status : </b>
                  <div className='text-green-500 flex items-center'>
                    <FaCheckCircle className="inline-block mr-2" />
                    Operational
                  </div>
                </span>
              ) : (
                <span className="flex items-center">
                  <b className='mr-2'>Status : </b>
                  <div className='text-red-400 flex items-center'>
                    <FaTimesCircle className="inline-block mr-2" />
                    Degraded
                  </div>
                </span>
              )}
            </Link>
          </div>
          <div className="px-4 mt-4">
            <b>Links</b>
            <Link to="/us" className="block mt-4">
              <span className="text-white text-sm">About Us</span>
            </Link>
            <Link to="/us/terms" className="block mt-4">
              <span className="text-white text-sm">Terms & Conditions</span>
            </Link>
            <Link to="/us/privacy" className="block mt-4">
              <span className="text-white text-sm">Privacy Policy</span>
            </Link>
            <Link to="/us/contact" className="block mt-4">
              <span className="text-white text-sm">Contact Us</span>
            </Link>
            <a to="https://rapidapi.com/organization/vitalsx-apis" className="block mt-4">
              <span className="text-white text-sm">API</span>
            </a>
          </div>
        </div>
        <br /><br />
        <div className="w-full px-4 md:px-8 rounded-xl py-8 md:py-12 bg-gray-600 text-white md:flex md:items-center md:justify-between">
          <span className="text-sm sm:text-base text-center md:text-left">
            © 2023. All Rights Reserved.
          </span>
          <div className="flex mt-4 md:mt-0">
            <Link
              to="https://www.instagram.com/vitalsxdotcom/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-xl text-gray-300 hover:text-white mr-4"
            >
              <FaInstagram />
            </Link>
            <Link
              to="https://www.pinterest.com/vitalsxdotcom/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-xl text-gray-300 hover:text-white"
            >
              <FaPinterest />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
